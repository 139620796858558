import React from 'react';
import ModalBase from './ModalBase';
import Link from '../Link';
import style from './style.module.css';

export default class DialogBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openClass: "",
            closeClass: ""
        }
        this.openTimer = 0;
        this.closeTimer = 0;
    }

    componentDidMount() {
        this.openTimer = setTimeout(() => {
            this.setState({
                openClass: " " + style.dialogOpen
            })
        }, 50)
    }

    componentWillUnmount() {
        clearTimeout(this.openTimer);
        clearTimeout(this.closeTimer);
    }

    onCloseHandler = (callPropsFunc) => {
        const { onClose = () => { } } = this.props;
        if (callPropsFunc === undefined) {
            callPropsFunc = true;
        }
        this.closeTimer = this.setState({
            closeClass: " " + style.dialogRemove
        }, () => {
            if (callPropsFunc) {
                this.openTimer = setTimeout(() => {
                    onClose();
                }, 650);
            }
        });
    }

    render() {
        const { className = style.modal, content = false, buttons = false, showClose = true, children, extraClasses = '' } = this.props;
        const { openClass, closeClass } = this.state;
        return (
            <ModalBase className={`${className}${extraClasses ? ` ${extraClasses}` : ``}`}>
                <div className={`${style.dialogBoxOverlayWrap}${openClass}${closeClass}`}>
                    {
                        showClose === true && (
                            <Link className={style.dialogClose} onClick={this.onCloseHandler}>
                                <span className={style.closeIcon}>&#215; </span>
                                <span className={style.closeText}>Close</span>
                            </Link>
                        )
                    }
                    <div className={`${style.dialogBoxOverlay}`}>
                        <div className={`dialogContent ${style.dialogContent}`}>
                            {content && content(this.onCloseHandler)}
                            {children}
                            {buttons && <div className={style.dialogButtons}>{buttons(this.onCloseHandler)}</div>}
                        </div>
                    </div>
                </div>
            </ModalBase>
        );
    }
}