import { combineReducers } from "redux";
import { reducer as forms } from 'redux-form';
import contentReducer from './contentReducer';
import storageReducer from './storageReducer';
import submitReducer from './submitReducer';
import listReducer from './listReducer';

const appReducer = combineReducers({
    form: forms,
    content: contentReducer,
    storage: storageReducer,
    submit: submitReducer,
    list: listReducer
})

export default appReducer;