
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';

import style from './style.module.css';

const Dropdown = (props) => {
    const { label = false, options = {}, input, meta: { error, touched }, serverError = "", placeholder = true, icon = true, defaultOption = false, onDropdownChange, prompt, filterInput = false } = props;

    const getPrompt = () => {
        if (placeholder === false) {
            return defaultOption || get(Object.values(options), "[0]", "");
        }
        return prompt || "Please select...";
    }

    const [sprompt] = useState(getPrompt());
    const [valueEntered, setValueEntered] = useState(get(options, input.value) || "");

    const onChangeHandler = (event) => {
        input.onChange(event);
        if (onDropdownChange) {
            onDropdownChange(event.target.value);
        }
    }

    const setDefaultValue = () => {
        const { onChange, value } = input;
        let labels = get(Object.values(options), "[0]", "");
        let selectedValue = get(options, value) || "";
        if (placeholder === false && selectedValue === "" && labels.length) {
            onChange(defaultOption || get(Object.keys(options), "[0]"));
        }
    }

    useEffect(() => {
        setDefaultValue();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setValueEntered(get(options, get(input, 'value')) || "");
        setDefaultValue();
        // eslint-disable-next-line
    }, [get(input, 'value'), options]);

    return (
        <div className={`${style.formGroup} ${style.dropdown}${touched && (error || serverError) ? " " + style.hasError : ""}${label === false ? " " + style.noLabel : ""}${filterInput ? " " + style.filterInput : ""}`}>
            <div className={style.textbox}>
                <span>{valueEntered || getPrompt()}</span>
                {
                    icon === true && (
                        <span className={style.dropdownIcon}>
                            <i className="fas fa-sort-down"></i>
                        </span>
                    )
                }
            </div>
            <select {...input} className={style.selectBox} onChange={onChangeHandler}>
                {
                    placeholder !== false && (
                        <option value="">{sprompt}</option>
                    )
                }
                {
                    options && Object.entries(options).map(([key, value]) => (
                        <option value={key} key={key}>{value}</option>
                    ))
                }
            </select>
        </div>
    )

}

export default Dropdown; 