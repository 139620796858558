import React, { Suspense, lazy, useEffect } from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { headerFooter } from './actions';

import 'react-toastify/dist/ReactToastify.css';
import './assets/css/fonts.css';
import './assets/css/style.css';
import './assets/css/pagination.css';

import Layout from './pages/Layouts';

const Page = lazy(() => import('./pages/Page'));
const Careers = lazy(() => import('./pages/Page/Careers'));
const Mock = lazy(() => import('./pages/Page/Mock'));

const SuspendPage = (element, fallback = <div />) => {
    return (
        <Suspense fallback={fallback}>
            {element}
        </Suspense>
    )
}

const App = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(headerFooter());
    }, [dispatch]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/careers/:id" element={SuspendPage(<Careers />)} />
                <Route element={<Layout />}>
                    {
                        process.env.REACT_APP_MODE !== "prod" && <Route path={`/mock/:url`} element={SuspendPage(<Mock />)} />
                    }
                    <Route path="*" element={SuspendPage(<Page />)} />
                </Route>
            </Routes>
            <ToastContainer />
        </BrowserRouter>
    )
}

export default App;