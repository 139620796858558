import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import Center from '../../components/Center';
import Link from '../../components/Link';
import Lazyload from '../../components/Lazyload';
import Subscribe from './Subscribe';
import style from './style.module.css';

const Footer = ({ width }) => {
    const { footer } = useSelector((state) => get(state, `storage`));
    const { address = [], contact = {}, footer_links = [], social_links = [], subcribe_text = "" } = footer;
    const socialsIcons = {
        'linkedin': "fa-brands fa-linkedin",
        'twitter': "fa-brands fa-twitter",
        'facebook': "fa-brands fa-facebook",
        'instagram': "fa-brands fa-instagram",
    }

    return (
        <div className={style.footer}>
            <Center>
                <Lazyload className={style.footerWrap} rootMargin="0px">
                    <div className={style.contact}>
                        <div className={style.title}>{get(contact, 'title', '')}</div>
                        <div className={style.icon}>
                            <i className="fa-regular fa-paper-plane-top"></i>
                            <Link to={get(contact, 'email.url', '')} className={style.iconText}>{get(contact, 'email.label', '')}</Link>
                        </div>
                        <div className={style.icon}>
                            <i className="fa-regular fa-mobile"></i>
                            <Link to={get(contact, 'phone.url', '')} className={style.iconText}>{get(contact, 'phone.label', '')}</Link>
                        </div>
                    </div>
                    <div className={style.locations}>
                        {
                            address.map((item, i) => (
                                <div className={style.location} key={`address_${i}`} >
                                    <div className={style.title}>{get(item, 'name', '')}</div>
                                    <div className={style.locationAddress}><p dangerouslySetInnerHTML={{ __html: get(item, 'address', '') }} /></div>
                                </div>
                            ))
                        }
                    </div>
                    {
                        width > 1200 && (
                            <div className={style.subscribe}>
                                <div className={style.title}>{subcribe_text}</div>
                                <Subscribe />
                            </div>
                        )
                    }
                </Lazyload>
            </Center>
            {
                width <= 1200 && (
                    <Lazyload className={style.links} rootMargin="0px">
                        <Center>
                            <div className={style.subscribe}>
                                <div className={style.title}>{subcribe_text}</div>
                                <Subscribe />
                            </div>
                        </Center>
                    </Lazyload>
                )
            }
            <Lazyload className={style.links} rootMargin="0px">
                <Center>
                    <div className={style.footerLinks}>
                        <div className={style.footerLinksMargin}>
                            {
                                footer_links.map((item, i) => (
                                    <div className={style.footerLinkItem} key={`f_link_${i}`}>
                                        <Link to={item.url}>{item.label}</Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className={style.socials}>
                        <div className={style.socialsMargin}>
                            {
                                social_links.map((item, i) => (
                                    <div className={style.socialItem} key={`f_s_${i}`}>
                                        <Link to={item.link}>
                                            <i className={socialsIcons[item.icon]}></i>
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Center>
            </Lazyload>
        </div>
    )
}

export default Footer;