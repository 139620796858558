let defaultState = {
	data: {},
	status: false,
	fetching: false,
	serverError: []
}

export default function contentReducer(state = {
	header_footer: defaultState,
	page: defaultState
}, action) {
	switch (action.type) {
		case "FETCH_CONTENT_PENDING": {
			const { meta: { attribute } } = action;
			return {
				...state,
				[attribute]: {
					...defaultState,
					fetching: true
				}
			}
		}
		case "FETCH_CONTENT_REJECTED": {
			const { meta: { attribute } } = action;
			return {
				...state,
				[attribute]: {
					...defaultState,
					serverError: {
						"error": "Error in fetching content"
					}
				}
			}
		}
		case "FETCH_CONTENT_FULFILLED": {
			const { meta: { attribute } } = action;
			const { data: { data, status } } = action.payload;
			return {
				...state,
				[attribute]: {
					...defaultState,
					data,
					status
				}
			}
		}
		default:
			return state;
	}
}