import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import $ from 'jquery-slim';
import { get } from 'lodash';

import Link from '../../components/Link';
import Center from '../../components/Center';
import DialogBox from '../../components/DialogBox';
import ModalBase from '../../components/DialogBox/ModalBase';
import HeaderSub from './HeaderSub';
import HeaderSubMobile from './HeaderSubMobile';

import style from './style.module.css';
import logo from '../../assets/images/logo.png';

const Header = ({ width = 0, offset = 0 }) => {
    const [open, setOpen] = useState(false);
    const [openSubmenu, setOpenSubmenu] = useState(false);
    const [menu, setMenu] = useState(false);
    const { header = {} } = useSelector((state) => get(state, `storage`));
    const links = get(header, 'links', []);

    useEffect(() => {
        if (open === false && $("#menu_close").length) {
            $("#menu_close").click();
        }
        else if (open === true) {
            setMenu(true);
        }
    }, [open, setMenu])

    const onSubmenuClick = (e, i) => {
        e.stopPropagation();
        setOpenSubmenu(prev => {
            if (prev === false || prev !== i) {
                return i;
            }
            return false;
        })
        if (i === false) {
            setOpen(false);
        }
    }

    return (
        <>
            <ModalBase className={style.header}>
                <Center>
                    <div className={style.headerCenter} onClick={(e) => onSubmenuClick(e, false)}>
                        <Link to="/" className={style.logo}>
                            <img src={logo} alt="Avanterra Logo" />
                        </Link>
                        <div className={`${style.headerLinks}`}>
                            {
                                links.map((linkItem, j) => {
                                    if (linkItem.has_dropdown === 1) {
                                        return (
                                            <div className={`${style.headerLinkItem} ${style.hasSubMenu}`} key={`f_title_${j}`}>
                                                <Link to={linkItem.url} className={style.hasSubMenu}>
                                                    <span>{linkItem.label}</span>
                                                </Link>
                                                <button type="button" onClick={(e) => onSubmenuClick(e, j)} className={style.hasSubMenu}>
                                                    <span className={style.caretIcon}>
                                                        <i className={`fa-solid fa-caret-down`}></i>
                                                    </span>
                                                </button>
                                            </div>
                                        )
                                    }
                                    return (
                                        <Link to={linkItem.url} className={style.headerLinkItem} key={`f_title_${j}`}>
                                            <span>{linkItem.label}</span>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                        <button
                            type="button"
                            className={`${style.burger} ${open === true ? " " + style.active : ""}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpen(prevOpen => {
                                    return !prevOpen
                                })
                            }}
                        />
                    </div>
                </Center>
                {
                    openSubmenu !== false && width > 800 && (
                        <HeaderSub {...links[openSubmenu]} onClick={(e) => onSubmenuClick(e, false)} />
                    )
                }
            </ModalBase>
            {
                menu === true && (
                    <DialogBox
                        className={style.mobileMenu}
                        showClose={false}
                        onClose={() => setMenu(false)}
                        content={(onClose) => (
                            <Center style={{ paddingTop: offset }}>
                                <div className={style.menuMargin}>
                                    {
                                        links.map((linkItem, j) => {
                                            if (linkItem.has_dropdown === 1) {
                                                return (
                                                    <>
                                                        <div className={`${style.mobileLinkItem} ${style.hasSubMenu}`} key={`f_title_${j}`}>
                                                            <Link to={linkItem.url} onClick={(e) => onSubmenuClick(e, false)}>{linkItem.label}</Link>
                                                            <button type="button" onClick={(e) => onSubmenuClick(e, j)}>
                                                                <span className={style.caretIcon}>
                                                                    <i className={`fa-solid fa-caret-down`}></i>
                                                                </span>
                                                            </button>
                                                        </div>
                                                        {
                                                            openSubmenu !== false && width <= 800 && j === openSubmenu && (
                                                                <HeaderSubMobile {...links[openSubmenu]} onClick={(e) => onSubmenuClick(e, false)} />
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                            return (
                                                <div className={style.mobileLinkItem} key={`f_title_${j}`}>
                                                    <Link to={linkItem.url} onClick={(e) => onSubmenuClick(e, false)}>{linkItem.label}</Link>
                                                </div>

                                            )
                                        })
                                    }
                                </div>
                                <button id="menu_close" onClick={() => onClose()} style={{ display: 'none' }}></button>
                            </Center>
                        )}
                    />
                )
            }
        </>
    )
}

export default Header;