import { get } from "lodash";

let defaultState = {
	fetching: false,
	status: false,
	data: [],
	order: {
		field: "id",
		sort_order: "desc"
	},
	original: [],
	list: [],
	item: {},
	serverError: [],
	end: true,
	total: 0,
	action: ''
}

export default function listReducer(state = {
	usecase: defaultState,
	blog: defaultState,
	job: defaultState
}, action) {
	switch (action.type) {
		case "FETCH_LIST_PENDING": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...defaultState,
					order: state[attribute].order,
					item: get(state[attribute], 'item', {}), //Setup here to prevent it from fetching all data for dropdowns
					action: action.meta.action,
					fetching: true,
				}
			}
		}
		case "FETCH_LIST_REJECTED":
		case "CRUD_REJECTED":
		case "APPEND_REJECTED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...defaultState,
					order: state[attribute].order,
				}
			}
		}
		case "FETCH_LIST_FULFILLED": {
			const { attribute, limit } = action.meta;
			return {
				...state,
				[attribute]: {
					...defaultState,
					order: state[attribute].order,
					status: get(action.payload.data, "status", 0),
					data: get(action.payload.data, "data", []),
					original: get(action.payload.data, "data.results", []),
					list: get(action.payload.data, "data.results", []),
					total: get(action.payload.data, "data.mastertotal"),
					listTotal: get(action.payload.data, "data.listtotal"),
					end: get(action.payload.data, "data.mastertotal") < limit ? true : false,
					item: get(state[attribute], 'item', {}) //Resetup here to prevent it from fetching all data for dropdowns
				}
			}
		}
		case "APPEND_PENDING": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					action: "append",
					fetching: true,
					status: false
				}
			}
		}
		case "APPEND_FULFILLED": {
			const { attribute, limit } = action.meta;
			let d = get(action.payload.data, "data.results", []);
			let list = [
				...state[attribute].list,
				...d
			];
			if (attribute === 'revision') {
				list = [...d];
			}
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					list: list,
					end: d.length < limit ? true : false,
					status: get(action.payload.data, "status", 400)
				}
			}
		}
		case "CRUD_PENDING": {
			const { attribute, crud_action } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					action: crud_action,
					status: false,
					fetching: crud_action
				}
			}
		}
		case "CRUD_FULFILLED": {
			const { attribute, crud_action } = action.meta;
			let status = get(action.payload.data, "status", 400);
			if (status === 200) {
				let new_data = state[attribute].list;
				if (crud_action === "create") {
					if (get(action.payload.data, "data", false) !== false) {
						new_data = [
							action.payload.data.data,
							...new_data
						];
					}
				}
				else if (crud_action === "delete" || crud_action === "restore") {
					let data = get(action.payload.data, "data", []);
					data.forEach(item => {
						let index = new_data.findIndex(d => item.id === d.id);
						if (index >= 0) {
							new_data.splice(index, 1, item);
						}
					})
				}
				else if (crud_action === "hide") {
					let data = get(action.payload.data, "data", []);
					data.forEach(item => {
						new_data = new_data.filter(d => item.id !== d.id)
					})
				}
				else if (crud_action === "update") {
					let data = get(action.payload.data, "data", {});
					let index = new_data.findIndex(d => data.id === d.id);
					if (index >= 0) {
						new_data.splice(index, 1, data);
					}
				}
				return {
					...state,
					[attribute]: {
						...state[attribute],
						fetching: false,
						status: get(action.payload.data, "status", 400),
						data: get(action.payload.data, "data", 400),
						action: crud_action,
						total: crud_action === "hide" ? state[attribute].total - 1 : state[attribute].total,
						list: [
							...new_data
						],
						serverError: []
					}
				}
			}
			return {
				...state,
				[attribute]: {
					...state[attribute],
					status: get(action.payload.data, "status", 400),
					fetching: false,
					serverError: get(action.payload.data, "data", [])
				}
			}
		}
		case "ORDER_ICON": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					order: {
						...action.payload
					}
				}
			}
		}
		case "LOAD_ITEM_PENDING": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: true,
					item: {}
				}
			}
		}
		case "LOAD_ITEM_REJECTED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false
				}
			}
		}
		case "LOAD_ITEM_FULFILLED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					item: get(action.payload.data, "data", {}),
					status: get(action.payload.data, "status", 400)
				}
			}
		}
		case "SETUP_ITEM": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					item: action.payload
				}
			}
		}
		case "RESET_ITEM": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					item: {}
				}
			}
		}
		case "SUBMIT_FULFILLED": {
			const { meta: { formData } } = action;
			const { data: { data, status } } = action.payload;
			if (get(formData, 'form') === "watchlist" && status === 200) {
				const type = get(formData, 'type');

				if (type === "remove") {
					let finalList = get(state.profilefund, 'list', []);
					finalList = finalList.filter(fl => fl.id !== get(data, 'share_id'));
					return {
						...state,
						profilefund: {
							...state.profilefund,
							list: finalList
						}
					}
				}
			}
			return {
				...state
			}
		}
		default:
			return state;
	}
}