import api from './api';

export function getContent(url, data) {
	if (url === "/")
		url = "/home"
	let attr = 'page' + url;
	let attribute = 'page';
	return (dispatch) => {
		dispatch({
			type: 'FETCH_CONTENT',
			payload: api.post('/' + attr, data),
			meta: { attribute: attribute }
		});
	};
}

export function getItems(attribute, data) {
	let url = attribute;
	return (dispatch) => {
		dispatch({
			type: "FETCH_LIST",
			payload: api.post("/" + url, data),
			meta: { attribute },
		});
	};
}

export function getItem(attribute, url, action = "fetch") {
	return (dispatch) => {
		dispatch({
			type: "LOAD_ITEM",
			payload: api.post("/" + attribute + "/" + url),
			meta: { attribute, action },
		});
	};
}

export function headerFooter() {
	return (dispatch) => {
		dispatch({
			type: 'HEADER_FOOTER',
			payload: api.post('/header-footer')
		});
	};
}

export function submit(attribute, data) {
	return (dispatch) => {
		dispatch({
			type: 'SUBMIT',
			payload: api.post(`/submit/${attribute}`, data),
			meta: { attribute }
		});
	};
}

export function submitReset(attribute, data) {
	return (dispatch) => {
		dispatch({
			type: 'SUBMIT_REST',
			payload: {},
			meta: { attribute }
		});
	};
}

export function loadMore(attribute, count, limit, data, action) {
	if (!data) {
		data = {}
	}
	return dispatch => {
		dispatch({
			type: "APPEND",
			payload: api.post('/' + attribute, {
				from: count,
				limit,
				...data
			}),
			meta: { attribute, action: action || "fetching", limit }
		})
	}
}