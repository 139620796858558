import React from 'react';
import css from './style.module.css';

const Center = (props) => {
    const { className = false, children } = props;
    return (
        <div {...props} className={`${css.center}${className === false ? "" : " " + className}`}>{children}</div>
    )
}

export default Center;