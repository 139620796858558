import React from 'react';
import { cleanInput } from "../../helpers/input";
import style from './style.module.css';

export default class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focus: false,
            value: cleanInput(props.input.value)
        }
    }

    componentDidMount() {
        const { input: { value, onChange }, emptyValue = false } = this.props;
        if (emptyValue !== false && !value.toString()) {
            onChange(emptyValue);
        }
    }

    componentDidUpdate(prevProps) {
        const { input: { value } } = this.props;
        if (value !== prevProps.input.value) {
            this.setState({
                value: cleanInput(value)
            })
        }
    }

    onFocusHandler = () => {
        this.setState({
            focus: true
        })
    }

    onBlurHandler = () => {
        this.setState({
            focus: false
        })
    }

    onChangeHandler = (e) => {
        const value = cleanInput(e.target.value);
        const { input: { onChange } } = this.props;
        onChange(value);
        this.setState({
            value: value
        })
    }

    render() {
        const { label = false, border = true, required = true, type, meta: { error, touched }, input, disabled = false, placeholder = "", serverError = [], filterInput = false, showSearchIcon = false } = this.props;
        const { focus, value } = this.state;
        return (
            <div className={`${style.formGroup}${label === false ? " " + style.noLabel : ""}${border === false ? " " + style.noBorder : ""}${touched && error ? " " + style.inputError : ""}${focus ? " " + style.inputFocus : ""}${filterInput ? " " + style.filterInput : ""}`}>
                {
                    type === "textarea" ? (
                        <textarea {...input} placeholder={placeholder} className={style.textbox} onFocus={this.onFocusHandler} onBlur={(e) => { input.onBlur(e); setTimeout(() => this.onBlurHandler()); }} onKeyUp={this.onChangeHandler} disabled={disabled}></textarea>
                    ) : (
                        <input type={type} {...input} placeholder={placeholder} className={style.textbox} onFocus={this.onFocusHandler} onBlur={(e) => { input.onBlur(e); setTimeout(() => this.onBlurHandler()); }} onKeyUp={this.onChangeHandler} disabled={disabled} />
                    )
                }
                {showSearchIcon === true && <div className={`${style.searchIcon}`}><i className="fas fa-search"></i></div>}
                {
                    label !== false && (
                        <div className={`${style.controlLabel}${focus || value ? " " + style.focus : ""}`}>
                            <span>{label}</span>
                            {
                                required && (
                                    <span className={style.required}> *</span>
                                )
                            }
                        </div>
                    )
                }
                {
                    touched && error && (
                        <p className={style.helpBlock}>{error}</p>
                    )
                }
                {
                    touched && !error && serverError.length > 0 && (
                        <p className={style.helpBlock}>{serverError}</p>
                    )
                }
            </div>
        )
    }
}