import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';

import style from './style.module.css';
import 'react-phone-number-input/style.css';
import { isFunction } from 'lodash';

const InputPhone = ({ input, meta, label, required = true, serverError = [] }) => {
    const [value, setValue] = useState(input.value || "");
    const { error, touched } = meta;

    useEffect(() => {
        if (isFunction(input.onChange)) {
            input.onChange(value);
        }
    }, [value, input]);

    useEffect(() => {
        if (input.value === "") {
            setValue(input.value);
        }
    }, [input.value]);

    return (
        <div className={`${style.formGroup} ${style.inputPhone} ${touched && error ? " " + style.inputError : ""}`}>
            <div className={`${style.controlLabel} ${style.focus}`}>
                <span>{label}</span>
                {
                    required && (
                        <span className={style.required}> *</span>
                    )
                }
            </div>
            <PhoneInput
                defaultCountry="GB"
                placeholder="Enter here"
                value={value}
                onChange={setValue}
            />
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
            {
                touched && !error && serverError.length > 0 && (
                    <p className={style.helpBlock}>{serverError}</p>
                )
            }
        </div>
    )
}

export default InputPhone;