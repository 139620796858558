import React, { useEffect, useState } from 'react';
import $ from 'jquery-slim';
import { Outlet } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import style from "./style.module.css";

const Layout = () => {
    const [dimesions, setDimensions] = useState({ height: 0, winWidth: window.innerWidth, winHeight: window.innerHeight});
    useEffect(() => {
        function updateDimensions() {
            setDimensions({
                height: $(`.${style.header}`).outerHeight(),
                winWidth: $(window).width(),
                winHeight: $(window).height()
            });
        }
        window.addEventListener('resize', updateDimensions);
        updateDimensions();
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, [])
    return (
        <>
            <Header width={dimesions.winWidth} offset={dimesions.height} />
            <div className={style.content} style={{ paddingTop: dimesions.height }}>
                <Outlet context={{ height: dimesions.height, winWidth: dimesions.winWidth, winHeight: dimesions.winHeight }} />
            </div>
            <Footer width={dimesions.winWidth} />
        </>
    )
}

export default Layout;