import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field } from 'redux-form';
import { required, email } from 'redux-form-validators';

import { submit, submitReset } from '../../actions';
import { showToast } from '../../helpers/notify';
import { Button, Input } from "../../components/Field";

import style from './style.module.css';

const Subscribe = ({ handleSubmit, onSubmit, invalid, submitState, reset, submitReset }) => {
    const { fetching, status, serverError } = submitState;

    useEffect(() => {
        if (status === 200) {
            showToast('Submitted Successfully');
            submitReset();
            reset();
        }
        if (status >= 400) {
            showToast('Failed to Submit');
        }
    }, [status, submitReset, reset]);

    return (
        <form name="subscribe" className={style.form} onSubmit={handleSubmit(onSubmit)}>
            <Field name="email" border={false} required={true} component={Input} validate={[required(), email()]} label="Email" serverError={serverError['leads-email']} />
            <div className={style.fieldButton}>
                <Button
                    submit={true}
                    color="white"
                    disabled={invalid}
                    label={"Sign up"}
                    fetching={fetching}
                />
            </div>
        </form>
    )
}

const mapStateToProps = (state) => {
    return {
        submitState: state.submit.newsletter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (data) => dispatch(submit('newsletter', data)),
        submitReset: () => dispatch(submitReset('newsletter')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'subscribe',
    enableReinitialize: true
})(Subscribe));