import React from 'react';
import Link from '../../components/Link';
import style from './style.module.css';

const HeaderSubMobile = ({ links = [], onClick }) => {
    return (
        <div className={style.mobileDropdownMenuLinks}>
            {
                links.map((linkItem, j) => (
                    <div className={style.mobileDropdownMobileLinkItem}>
                        <Link key={`submenu_title_${j}`} to={linkItem.url} onClick={onClick}>
                            <span>{linkItem.label}</span>
                        </Link>
                    </div>
                ))
            }
        </div>
    )
}

export default HeaderSubMobile;