import Cookies from 'universal-cookie';

export const getJSON = (key, def = {}) => {
    if (localStorage.getItem(key)) {
        return JSON.parse(localStorage.getItem(key));
    }
    return def;
}

export const setJSON = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
}

export const getSort = (key, def) => {
    let data = getJSON(key);
    if (data.field && data.order) {
        return data;
    }
    return def;
}

export const setSort = (key, data) => {
    if (data.field && data.order) {
        setJSON(key, data);
    }
}

export const getCookie = (cookiename = "token", parseJson = false) => {
    // Get name followed by anything except a semicolon
    var cookiestring = RegExp(cookiename + "=[^;]+").exec(document.cookie);
    // Return everything after the equal sign, or an empty string if the cookie name not found
    cookiestring = decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
    if (parseJson && cookiestring) {
        cookiestring = JSON.parse(cookiestring);
    }
    return cookiestring || "";
}

export const setCookie = (value, cookiename = "token", remember = true) => {
    const cookies = new Cookies();
    if (!remember) {
        cookies.set(cookiename, value, {
            path: "/",
            secure: true,
            sameSite: 'Strict'
        });
    }
    else {
        cookies.set(cookiename, value, {
            path: "/",
            secure: true,
            sameSite: 'Strict'
        });
    }
}

export const deleteCookie = (cookiename = "token") => {
    const cookies = new Cookies();
    cookies.remove(cookiename, {
        path: "/",
        secure: true,
        sameSite: 'Strict'
    });
}