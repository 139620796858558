import { get } from 'lodash';

export default function storageReducer(
	state = {
		header: false,
		footer: false
	},
	action
) {
	switch (action.type) {
		case 'HEADER_FOOTER_FULFILLED': {
			const { header = false, footer = false } = get(action.payload, 'data.data');
			return {
				...state,
				header,
				footer
			};
		}
		default:
			return state;
	}
}