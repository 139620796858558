import { isEmpty } from 'lodash';
import React, { useEffect, useRef } from 'react';
import Center from '../../components/Center';
import Button from '../../components/Field/Button';
import Link from '../../components/Link';
import style from './style.module.css';

const HeaderSub = ({ content = {}, links = [], onClick }) => {
    const { title, text, button_label, button_url } = content;
    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(e) {
            if (ref.current.contains(e.target)) {
                return false;
            }
            onClick(e);
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        }
    }, [onClick])

    return (
        <div ref={ref} className={style.dropdownMenu}>
            <Center>
                <div className={style.dropdownMenuContent}>
                    <div className={"section_title"}>{title}</div>
                    <div className={`section_text ${style.dropdownText}`}>{text}</div>
                    {
                        isEmpty(button_label) === false && (
                            <div className={style.dropdownButton}>
                                <Button label={button_label} link={button_url} onClick={onClick} />
                            </div>
                        )
                    }
                </div>
                <div className={style.dropdownMenuLinks}>
                    {
                        links.map((linkItem, j) => (
                            <Link className={style.dropdownMenuLinkItem} key={`submenu_title_${j}`} to={linkItem.url} onClick={onClick}>
                                <span>{linkItem.label}</span>
                                <div className={style.arrow}>
                                    <i className={`fa-regular fa-angle-right`}></i>
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </Center>
        </div>
    )
}

export default HeaderSub;