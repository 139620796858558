let defaultState = {
    data: {},
    status: false,
    fetching: false,
    serverError: {}
}

export default function submitReducer(state = {
    contact: defaultState,
    newsletter: defaultState,
    bookademo: defaultState
}, action) {
    switch (action.type) {
        case "SUBMIT_PENDING": {
            const { meta: { attribute } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    fetching: true
                }
            }
        }
        case "SUBMIT_REJECTED": {
            const { meta: { attribute } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    status: 500,
                }
            }
        }
        case "SUBMIT_FULFILLED": {
            const { meta: { attribute } } = action;
            const { data: { data, status } } = action.payload;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    data,
                    status,
                    serverError: status >= 400 ? data : {},
                }
            }
        }
        case "SUBMIT_REST": {
            const { meta: { attribute } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    status: false,
                    serverError: {}
                }
            }
        }
        default:
            return state;
    }
}